<template>
  <div class="row mt-0 mb-3 px-3">
    <div class="col-md-6">
      <a
        class="btn btn-block btn-dark"
        data-toggle="collapse"
        href="#cara"
        role="button"
        aria-expanded="false"
        aria-controls="collapseExample"
      >
        Procesos
      </a>
      <div class="collapse" id="cara">
        <div class="card card-body">
          <div class="row">
            <div class="col-md-10">
              <div class="form-group">
                <!-- @change="findFilterBar($event)" -->
                <v-select
                  :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                  v-model="formProceso.proceso"
                  label="descripcion"
                  :options="listasForms.procesosList"
                  placeholder="Procesos"
                ></v-select>
              </div>
            </div>
            <div class="col-md-2">
              <button
                class="btn bg-info col-md-12"
                v-show="!$v.formProceso.$invalid"
                @click="cargarProceso()"
              >
                <i class="fas fa-save"></i>
              </button>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <!-- @change="findFilterBar($event)" -->
              <table
                class="table table-bordered table-striped table-hover table-sm"
              >
                <thead>
                  <tr>
                    <th>Nombre</th>
                    <th style="width: 20px">Opciones</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(data, index) in procesos" :key="index">
                    <td>
                      {{ data.descripcion }}
                    </td>
                    <td class="text-center">
                      <button
                        type="button"
                        class="btn btn-sm btn-danger"
                        @click="destroyProceso(data.id, data.pivot.sitio_id)"
                      >
                        <i class="fas fa-trash-alt"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <a
        class="btn btn-block btn-dark"
        data-toggle="collapse"
        href="#inspe"
        role="button"
        aria-expanded="false"
        aria-controls="collapseExample"
      >
        Inspecciones
      </a>
      <div class="collapse" id="inspe">
        <div class="card card-body">
          <div class="row">
            <div class="col-md-10">
              <div class="form-group">
                <v-select
                  :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                  v-model="inspeccion"
                  label="nombre"
                  :options="listasForms.inspeccionesList"
                  placeholder="Inspección"
                  @input="selectInspeccion()"
                ></v-select>
              </div>
            </div>
            <div class="col-md-2">
              <button
                class="btn bg-info col-md-12"
                v-if="formInspeccion.formato_inspeccion_id"
                @click="cargarInspeccion()"
              >
                <i class="fas fa-save"></i>
              </button>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <!-- @change="findFilterBar($event)" -->
              <table
                class="table table-bordered table-striped table-hover table-sm"
              >
                <thead>
                  <tr>
                    <th>Nombre</th>
                    <th style="width: 20px">Opciones</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(data, index) in inspecciones" :key="index">
                    <td>
                      {{ data.nombre }}
                    </td>
                    <td class="text-center">
                      <button
                        type="button"
                        class="btn btn-sm btn-danger"
                        @click="destroyInspeccion(data.id, data.pivot.tanque)"
                      >
                        <i class="fas fa-trash-alt"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";
import axios from "axios";
import { required, minLength, email } from "vuelidate/lib/validators";

export default {
  name: "SitioProcesosInspecciones",
  components: {
    vSelect,
  },
  data() {
    return {
      inspeccion: null,
      formProceso: {},
      formInspeccion: {},
      listasForms: {
        inspeccionesList: [],
        procesosList: [],
      },
      procesos: [],
      inspecciones: [],
    };
  },

  validations: {
    formProceso: {
      proceso: {
        required,
      },
    },
    formInspeccion: {
      formato_inspeccion_id: {
        required,
      },
    },
  },

  methods: {
    getProcesos() {
      axios
        .get("/api/admin/sitios/getProcesos/" + this.$parent.form.id)
        .then((response) => {
          this.listasForms.procesosList = response.data;
        });
    },

    getSitiosHasProcesos() {
      axios
        .get("/api/admin/sitios/show/" + this.$parent.form.id)
        .then((response) => {
          if (response.data[0].procesos != undefined) {
            this.procesos = response.data[0].procesos;
          }
        });
    },

    selectInspeccion() {
      this.formInspeccion.formato_inspeccion_id = this.inspeccion.id;
    },

    getInspecciones() {
      axios.get("/api/admin/formatosInspeccion/lista").then((response) => {
        this.listasForms.inspeccionesList = response.data;
      });
    },

    getSitiosHasInspecciones() {
      axios
        .get("/api/admin/sitios/show/" + this.$parent.form.id)
        .then((response) => {
          if (response.data[0].inspecciones != undefined) {
            this.inspecciones = response.data[0].inspecciones;
          }
        });
    },

    cargarProceso() {
      this.formProceso.proceso_id = this.formProceso.proceso.id;
      this.formProceso.sitio_id = this.$parent.form.id;
      this.formProceso.accion = true;
      if (!this.$v.formProceso.$invalid) {
        axios({
          method: "PUT",
          url: "/api/admin/sitios/assingOrRevokeProceso",
          data: this.formProceso,
        })
          .then(() => {
            this.formProceso = {};
            this.getProcesos();
            this.$swal({
              icon: "success",
              title: "Se guardo exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            this.getSitiosHasProcesos();
          })
          .catch((e) => {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error, el proceso ya se encuentra asignado",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    cargarInspeccion() {
      this.formInspeccion.sitio_id = this.$parent.form.id;
      this.formInspeccion.accion = true;
      if (!this.$v.formInspeccion.$invalid) {
        axios({
          method: "PUT",
          url: "/api/admin/sitios/assingOrRevokeInspeccion",
          data: this.formInspeccion,
        })
          .then(() => {
            this.formInspeccion = {};
            this.inspeccion = {};
            this.$swal({
              icon: "success",
              title: "Se guardo exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            this.getSitiosHasInspecciones();
          })
          .catch((e) => {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error, la inspeccion ya se encuentra asignada",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    destroyInspeccion(idInspeccion, idSitio) {
      this.$swal({
        title: "Esta seguro de eliminar esta inspección?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Eliminar!",
      }).then((result) => {
        // <--
        if (result.value) {
          axios({
            method: "PUT",
            url: "/api/admin/sitios/assingOrRevokeInspeccion",
            data: {
              sitio_id: idSitio,
              formato_inspeccion_id: idInspeccion,
              accion: false,
            },
          }).then(() => {
            this.getSitiosHasInspecciones();
            this.$swal({
              icon: "success",
              title: "Se elimino la inspección exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 4000,
              timerProgressBar: true,
            });
          });
        }
      });
    },

    destroyProceso(idProceso, idSitio) {
      this.$swal({
        title: "Esta seguro de eliminar este proceso?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Eliminar!",
      }).then((result) => {
        if (result.value) {
          axios({
            method: "PUT",
            url: "/api/admin/sitios/assingOrRevokeProceso",
            data: {
              sitio_id: idSitio,
              proceso: idProceso,
              accion: false,
            },
          }).then(() => {
            this.getSitiosHasProcesos();
            this.getProcesos();
            this.$swal({
              icon: "success",
              title: "Se elimino la inspección exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 4000,
              timerProgressBar: true,
            });
          });
        }
      });
    },
  },

  mounted() {
    this.getProcesos();
    this.getInspecciones();
    this.getSitiosHasInspecciones();
    this.getSitiosHasProcesos();
  },
};
</script>
